
import * as actionTypes from './constants';

const getBannersRequest = (payload) => {
    return {
        type: actionTypes.GET_BANNERS_REQUEST,
        payload,
    };
}

const getBannersSuccess = (payload) => {
    return {
        type: actionTypes.GET_BANNERS_SUCCESS,
        payload,
    };
}

const getBannersError = (payload) => {
    return {
        type: actionTypes.GET_BANNERS_ERROR,
        payload,
    };
}

const getCategoryRequest = (payload) => {
    return {
        type: actionTypes.GET_CATEGORY_REQUEST,
        payload,
    };
}

const getCategorySuccess = (payload) => {
    return {
        type: actionTypes.GET_CATEGORY_SUCCESS,
        payload,
    };
}

const getCategoryError = (payload) => {
    return {
        type: actionTypes.GET_CATEGORY_ERROR,
        payload,
    };
}

const loginRequest = (payload) => {
    console.log("login input")
    return {
        type: actionTypes.LOGIN_REQUEST,
        payload,
    };
}

const loginSuccess = (payload) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        payload,
    };
}

const loginError = (payload) => {
    console.log("loginError", payload);
    return {
        type: actionTypes.LOGIN_ERROR,
        payload,
    };
}

const getProductListRequest = (payload) => {
    console.log('getproductlist---==');
    return {
        type: actionTypes.GET_PRODUCT_LIST_REQUEST,
        payload,
    };
}

const getProductListSuccess = (payload) => {
    return {
        type: actionTypes.GET_PRODUCT_LIST_SUCCESS,
        payload,
    };
}

const getProductListError = (payload) => {
    return {
        type: actionTypes.GET_PRODUCT_LIST_ERROR,
        payload,
    };
}


export {
    getBannersRequest,
    getBannersSuccess,
    getBannersError,
    getCategoryRequest,
    getCategorySuccess,
    getCategoryError,
    loginRequest,
    loginSuccess,
    loginError,
    getProductListRequest,
    getProductListSuccess,
    getProductListError
}