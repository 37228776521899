import * as actionTypes from "./constants";

const initialAuthState = {
    loading: false,
    error: false,
    isLoggedIn: false,
    token: null,
    email: null,
    role: null
};

const initialBannersState = {
    loading: false,
    error: false,
    data: null
};

const initialCategoryState = {
    loading: false,
    error: false,
    data: null
};

const bannersReducer = (state = initialBannersState, action) => {
    console.log("action.type", action.payload);
    switch (action.type) {
        case actionTypes.GET_BANNERS_REQUEST:
            return { ...state, loading: true, data: null };

        case actionTypes.GET_BANNERS_SUCCESS:
            return { ...state, loading: false, data: action?.payload, error: false }

        case actionTypes.LOGIN_ERROR:
            return { ...state, loading: false, error: action?.payload }
        default:
            return state;
    }
}

const categoryReducer = (state = initialCategoryState, action) => {
    console.log("action.type", action.payload);
    switch (action.type) {
        case actionTypes.GET_CATEGORY_REQUEST:
            return { ...state, loading: true, data: null };

        case actionTypes.GET_CATEGORY_SUCCESS:
            return { ...state, loading: false, data: action?.payload, error: false }

        case actionTypes.GET_CATEGORY_ERROR:
            return { ...state, loading: false, error: action?.payload }
        default:
            return state;
    }
}

const authReducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
            return { ...state, loading: true };

        case actionTypes.LOGIN_SUCCESS:
            return { ...state, loading: false, isLoggedIn: true, token: action?.payload?.token, email: action?.payload?.user?.email, role: action?.payload?.user?.role }

        case actionTypes.LOGIN_ERROR:
            return { ...state, loading: false, error: action?.payload }
        default:
            return state;
    }
}

const rootReducer = { auth: authReducer, banners: bannersReducer, categories: categoryReducer };

export { authReducer, bannersReducer, categoryReducer, rootReducer };