import Wrapper from "./styled";

const HeaderHome = () => {
    return (
        <Wrapper>
            <div className="header">
                <div class="header-inner row">
                    <div className="logo col-5 col-sm-4 col-md-2"><a href="/"><img src="meddey_header_logo.png" alt="logo-image" /></a></div>
                    <div className="search-form col-7 col-sm-8 col-md-6 search-bar">
                        <input type="text" placeholder="Search Products ..." />
                        {/* <input className="search-bar" type="submit" /> */}
                        <i class="fa fa-search search-icon"></i>
                    </div>
                    {/* <div class="header-right"> */}
                    <div className="col-7 col-sm-8 col-md-2 link"><a href="tel:+918586-012345"><i class="fa fa-phone"></i>+91 8586-012345</a></div>
                    <div className="col-7 col-sm-8 col-md-1 link"><i class="fa fa-shopping-cart cart"></i></div>
                    <div className="col-5 col-sm-4 col-md-1 link login-link">Login</div>
                    {/* </div> */}
                </div>
            </div>
        </Wrapper>
    )
}

export default HeaderHome;