import { takeLatest, all, call, put } from 'redux-saga/effects'
import * as actionTypes from './constants'
import AuthService from '../services/authServices';
import ApiUrls from "../services/apiUrls";
import { getBannersError, getBannersSuccess, getCategoryError, getCategorySuccess } from './actions';

function* getBannersList(inputData) {
  try {
    const response = yield call(AuthService.postJsonData, ApiUrls.GET_BANNERS_LIST, {page:1})
    console.log("response", response)
    if (response.data?.status == 200) {
      yield put(getBannersSuccess(response?.data?.data));
    } else {
      yield put(getBannersError(response?.message));
    }
  } catch (error) {
    console.log("error", error)
    yield put(getBannersError(error));
  }
}

function* getCategoryList(inputData) {
  try {
    const response = yield call(AuthService.postJsonData, ApiUrls.GET_CATEGORIES, {page:1})
    console.log("response", response)
    if (response.data?.status == 200) {
      yield put(getCategorySuccess(response?.data?.data));
    } else {
      yield put(getCategoryError(response?.message));
    }
  } catch (error) {
    console.log("error", error)
    yield put(getBannersError(error));
  }
}

function* loginRequest(inputData) {
  console.log("login api called", inputData?.payload)

}

function* logoutRequest(inputData) {
  console.log("logout api called", inputData?.payload)
}

function* signupRequest(inputData) {
  console.log("signup api called", inputData?.payload)
}

function* getProductsList(inputData) {
  console.log("getproducts---saga", inputData?.payload)
}

const bannersSaga = [
    takeLatest(actionTypes.GET_BANNERS_REQUEST, getBannersList),
  ]

const catgoriesSaga = [
    takeLatest(actionTypes.GET_CATEGORY_REQUEST, getCategoryList),
  ]

const authSaga = [
    takeLatest(actionTypes.LOGIN_REQUEST, loginRequest),
    takeLatest(actionTypes.LOGOUT_REQUEST, logoutRequest),
    takeLatest(actionTypes.SIGNUP_REQUEST, signupRequest),
    takeLatest(actionTypes.GET_PRODUCT_LIST_REQUEST, getProductsList),
  ]

function* rootSaga() {
    yield all([
        ...authSaga, ...bannersSaga, catgoriesSaga
    ]);
}

export { rootSaga };
