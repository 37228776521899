import styled from "styled-components";

const Wrapper = styled.div`
    color:white;
    .header {
        background: #006093;
        padding: 10px 20px;
    }
    .header-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height:50px;
    }
    a {
        color: wheat;
        text-decoration: none;
    }
    .search-form {
        padding:auto 10px;
        input {
            width:90%;
            height: 32px;
        }
    }
    .search-icon {
        position: relative;
        cursor: pointer;
        right: 37px;
        bottom: -1px;
        height: 30px;
        background: orange;
        padding: 2px 7px;
        font-size: 24px;
    }
    input:focus {
        outline:none;
    }
    .search-bar input[type=submit] {
        width: 35px;
        background: #f48022 url(../images/icon-search.png) center center no-repeat;
        padding: 0;
        cursor: pointer;
        display: inline;
        position: relative;
        left: -35px;
        top: -2px;
        vertical-align: middle;
    }
    .link{
        cursor:pointer;
        color:white;
        font-weight:bold;
    }
    .cart {
        font-size: 26px;
        color: #f48022;
    }
    @media screen and (min-width:767px) { 
    }

    @media screen and (max-width:767px) {

    }

`;
const Subheader = styled.div`
    padding: 10px;
    background: #fc6c15;
    display: flex;
    column-gap: 12px;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 10px;

    .tab {
        cursor:pointer;
        color: #fff;
        display: inline-block;
        height: 23px;
        line-height: 23px;
        border: 1px solid #fff;
        padding: 4px 12px;
        border-radius: 12px;
        text-decoration: none;
        text-transform: uppercase;
        font: 700 13px open sans, sans-serif;
    }
    @media screen and (min-width:767px) { 
    }

    @media screen and (max-width:767px) {

    }

`;
export default Wrapper;
export { Subheader }
