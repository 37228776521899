export const GET_BANNERS_REQUEST = 'meddeygo_web/banners/GET_BANNERS_REQUEST'
export const GET_BANNERS_SUCCESS = 'meddeygo_web/banners/GET_BANNERS_SUCCESS'
export const GET_BANNERS_ERROR = 'meddeygo_web/banners/GET_BANNERS_ERROR'

export const GET_CATEGORY_REQUEST = 'meddeygo_web/categories/GET_CATEGORY_REQUEST'
export const GET_CATEGORY_SUCCESS = 'meddeygo_web/categories/GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_ERROR = 'meddeygo_web/categories/GET_CATEGORY_ERROR'




export const LOGIN_REQUEST = 'meddeygo_web/auth/LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'meddeygo_web/auth/LOGIN_SUCCESS'
export const LOGIN_ERROR = 'meddeygo_web/auth/LOGIN_ERROR'

export const LOGOUT_REQUEST = 'meddeygo_web/auth/LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'meddeygo_web/auth/LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'meddeygo_web/auth/LOGOUT_ERROR'

export const SIGNUP_REQUEST = 'meddeygo_web/auth/SIGNUP_REQUEST'
export const SIGNUP_SUCCESS = 'meddeygo_web/auth/SIGNUP_SUCCESS'
export const SIGNUP_ERROR = 'meddeygo_web/auth/SIGNUP_ERROR'

export const GET_PRODUCT_LIST_REQUEST = 'meddeygo_web/products/GET_PRODUCT_LIST_REQUEST'
export const GET_PRODUCT_LIST_SUCCESS = 'meddeygo_web/products/GET_PRODUCT_LIST_SUCCESS'
export const GET_PRODUCT_LIST_ERROR = 'meddeygo_web/products/GET_PRODUCT_LIST_ERROR'