import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Navbar from './common-modules/Header/HomeHeader';
import Cart from './modules/Cart';
import Home from './modules/Home/Home';

const App = () => {
  console.log("a[[pp.js");
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/home',
      element: <Home />
    },
    {
      path: '/viewcart',
      element: <Cart />
    }
  ])
  return (
    <RouterProvider router={router}/>
  );
}

export default App;
