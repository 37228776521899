import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { clone, pathOr, mergeDeepRight } from 'ramda';

import {rootSaga} from './saga';
import {rootReducer} from './reducers';

const loadState = () => {
  try {
    const reduxState = JSON.parse(localStorage.getItem("reduxState"))
    console.log("reduxState",reduxState)
    if (reduxState == null) {
      return undefined
    }
    return reduxState

  } catch (err) {
    return undefined
  }
}

const saveState = (stateData) => {
  try {
    stateData && localStorage.setItem("reduxState", JSON.stringify(stateData))
  } catch (error) {
      console.log("error in save state", error)
  }
}

const persistedState = loadState() || {}


const bindMiddleware = (middleware) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore = (initialState, context) => {
    console.log("rootredu", rootReducer)
  const sagaMiddleware = createSagaMiddleware({
    onError(err) {
        console.log(`err in saga`, err)
    },
  });
  console.log("rootredu", rootReducer)
  const store = createStore(combineReducers(rootReducer), mergeDeepRight(initialState, persistedState), bindMiddleware([sagaMiddleware]));
  console.log("after store")

  store.subscribe(()=>{
    const state = clone(store.getState());
    // const state = store.getState()
    console.log("stogetState", state)
    saveState({auth : state.auth, banners: state.banners, categories: state.categories})
  })
  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const wrapper = makeStore;
