import React from "react";
import styled from "styled-components";
const HomeHeader = React.lazy(() => import('../../common-modules/Header/HomeHeader'));
const SubHeader = React.lazy(() => import('../../common-modules/Header/SubHeader'));
const ProductsCats = React.lazy(() => import('./components/ProductsCats'));
const Banners = React.lazy(() => import('../Banners'));

const Home = () => {
    return (
        <Wrapper>
            <HomeHeader />
            <SubHeader />
            <Banners />
            <ProductsCats />

            <img src="https://meddey.in/uploads/images/banner_images/1678542811_20000_customer.jpg" />
        </Wrapper>
    )
}

export default Home;

const Wrapper = styled.div`
    // display:flex;

`